import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ResetSuccess = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>Password Reset Successful</h1>
      <p>Your password has been updated successfully.</p>
      <p>When you're ready, click below to access your account:</p>
      <button
        onClick={() => loginWithRedirect({ appState: { returnTo: "/my-account" } })}
        style={{
          backgroundColor: "#007BFF",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "16px",
          marginTop: "20px",
        }}
      >
        Go to My Account
      </button>
    </div>
  );
};

export default ResetSuccess;
